import React from "react"
import AppLayout from "../../components/app/app-components/layout"
import BlankCard from "../../components/app/app-components/blankCard"
import { motion } from "framer-motion"
import { myAnimations } from "../../components/context/framer-animations"

const Notifications = ({ path }) => {
  return (
    <AppLayout title="Articles" path={path} isBackButton={true}>
      <motion.div {...myAnimations.slideleft} className="m-5 space-y-4">
        <div className="mb-4 font-base">Notifications</div>
        <BlankCard text="No new notifications" />
        <div className="pt-8 mb-4"></div>
      </motion.div>
    </AppLayout>
  )
}

export default Notifications
